module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"contentWidth":920,"headerSize":32,"headerPadding":12,"shutterEffect":true,"breakpoints":[480,768,1024,1200],"colors":{"primary":"#fa9f38","white":"#fafafa","dark":"#5c5c5c","black":"#262626","primary-5":"#de871d","primary-4":"#e38c23","primary-3":"#e99129","primary-2":"#ef952e","primary-1":"#f49a33","primary1":"#ffa43d","primary2":"#ffa942","primary3":"#ffae47","primary4":"#ffb24c","primary5":"#ffb750","white-5":"#e0e0e0","white-4":"#e5e5e5","white-3":"#eaeaea","white-2":"#f0f0f0","white-1":"#f5f5f5","white1":"#ffffff","dark-5":"#474747","dark-4":"#4b4b4b","dark-3":"#4f4f4f","dark-2":"#535353","dark-1":"#585858","dark1":"#606060","dark2":"#656565","dark3":"#696969","dark4":"#6e6e6e","dark5":"#727272","black-5":"#141414","black-4":"#171717","black-3":"#1b1b1b","black-2":"#1f1f1f","black-1":"#222222","black1":"#2a2a2a","black2":"#2e2e2e","black3":"#323232","black4":"#353535","black5":"#393939"}},"pathToConfigModule":"src/utils/jss.ts"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simplific","short_name":"Simplific","start_url":"/","background_color":"#fafafa","theme_color":"#fa9f38","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f787aacb4ba4eb3ba8a610e47a676cf0"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
